import { useEffect, useState } from 'react';
import css from './Header.module.css'

const Header = (props) => {
  const [date, setDate] = useState('');
  
  const toggleMatrix = () => {
    if (sessionStorage.getItem('matrix') === null && localStorage.getItem('theme') === 'dark') {
      sessionStorage.setItem('matrix', 'true');
    } else {
      sessionStorage.removeItem('matrix');
      props.onClick(sessionStorage.getItem('matrix'));
    }
    props.onClick(sessionStorage.getItem('matrix'));
  };

  useEffect(() => {
    let now = new Date();
    setDate(now.toLocaleString());
    const interval = setInterval(() => {
      now = new Date();
      setDate(now.toLocaleString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  return (
    <header className={css.header}>
      <p className={css.windowName}>Un1xC0rn@MrR0m4n:~</p>
      <p 
        onClick={toggleMatrix} 
        className={css.date}>{date}
      </p>
    </header>
  );
};

export default Header;