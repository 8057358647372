import css from './TextElement.module.css';

const TextElement = (props) => {
  return (
    <li className={css.listItem}>
      <p
        className={props.name ? css.typer : ''}
        style={{ animationDelay: `${props.delay}s`, animationDuration: `${props.time}s`, animationTimingFunction: `steps(${props.steps} ,end)`, animationFillMode: 'forwards' }}
      >
        {'>'} {props.src ? <img src={props.src} alt={props.alt}></img> : ''} {props.text}
      </p>
    </li>
  );
};

export default TextElement;
