import { useEffect } from 'react';

import LinkElement from '../layout/LinkElement';

import css from './Welcome.module.css';

const Welcome = () => {
  const playAnimation = true;

  useEffect(() => {
    sessionStorage.setItem('playAnimation', 'true');
  }, []);

  return (
      <section className={"container"}>
        <ul className={css.header}>
          <li>
            <LinkElement name={playAnimation} blink={true} delay={0} time={.5} steps={10} to="/main" text="Welcome" />
          </li>
        </ul>
      </section>
  );
};

export default Welcome;
