import { useEffect, useState } from 'react';

import LinkElement from '../layout/LinkElement';
import TextElement from '../layout/TextElement';

const NotFound404 = () => {
  const [playAnimation, setPlayAnimation] = useState(true);

  useEffect(() => {
    setPlayAnimation(JSON.parse(sessionStorage.getItem('playAnimation')));
  }, []);

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('playAnimation', 'false'));
  }, []);

  return (
      <section className={'containerLinks'}>
        <ul>
          <TextElement name={playAnimation} delay={0.2} time={0.1} steps={1} text="404 | PAGE NOT FOUND" />
          <LinkElement name={playAnimation} blink={true} delay={0.2} time={0.1} steps={1} to="/" text="<Back>" />
        </ul>
      </section>
  );
};

export default NotFound404;
