import { useEffect, useState, Fragment } from 'react';

import LinkElement from '../layout/LinkElement';
import TextElement from '../layout/TextElement';
import ThreeJs from '../threeJs/ThreeJs';

import about from '../../assets/img/about.svg';
import js from '../../assets/img/js.svg';
import imprint from '../../assets/img/imprint.svg';
import node from '../../assets/img/node.svg';
import react from '../../assets/img/react.svg';

const Main = () => {
  const [date, setDate] = useState('');
  const [model, setModel] = useState('microchip');
  const [playAnimation, setPlayAnimation] = useState(true);

  useEffect(() => {
    let now = new Date();
    now.setMinutes(now.getMinutes() - 17);
    setDate(now.toLocaleString());
  }, []);

  useEffect(() => {
    setPlayAnimation(JSON.parse(sessionStorage.getItem('playAnimation')));
  }, []);

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('playAnimation', 'true'));
  }, []);

  return (
    <Fragment>
      <section className={'containerLinks'}>
        <ul>
          <TextElement name={playAnimation} delay={0.2} time={0.1} steps={1} text="Mr_R0m4n_OS 7.0.3" />
          <TextElement name={playAnimation} delay={0.6} time={0.1} steps={1} text="login: Un1xC0rn" />
          <TextElement name={playAnimation} delay={1} time={0.1} steps={5} text="Password:" />
          <TextElement name={playAnimation} delay={1.8} time={0.1} steps={1} text={`Last login: ${date}`} />
          <TextElement name={playAnimation} delay={2.2} time={0.2} steps={5} text="cd projects/" />
          <TextElement name={playAnimation} delay={2.5} time={0.2} steps={5} text="ls" />
          <li>
            <ul className={'margin'}>
              <li 
                onMouseEnter={() => setModel('about')} 
                onMouseLeave={() => setModel('microchip')}
                onTouchStart={() => setModel('about')} 
                onTouchEnd={() => setModel('microchip')}
              >
               <LinkElement name={playAnimation} delay={2.9} time={0.1} steps={1} to="/aboutme" src={about} alt={'About me'} text="aboutMe/" />
              </li>
              <li 
                onMouseEnter={() => setModel('js')} 
                onMouseLeave={() => setModel('microchip')}
                onTouchStart={() => setModel('js')} 
                onTouchEnd={() => setModel('microchip')}
              >
               <LinkElement name={playAnimation} delay={3.1} time={0.1} steps={1} to="/javascript" src={js} alt={'JavaScript logo'} text="javaScript/" />
              </li>
              <li 
                onMouseEnter={() => setModel('react')} 
                onMouseLeave={() => setModel('microchip')}
                onTouchStart={() => setModel('react')} 
                onTouchEnd={() => setModel('microchip')}
              >
               <LinkElement name={playAnimation} delay={3.3} time={0.1} steps={1} to="/react" src={react} alt={'React.js logo'} text="reactJs/" />
              </li>
              <li 
                onMouseEnter={() => setModel('node')} 
                onMouseLeave={() => setModel('microchip')}
                onTouchStart={() => setModel('node')} 
                onTouchEnd={() => setModel('microchip')}
              >
                <LinkElement name={playAnimation} delay={3.5} time={0.1} steps={1} to="/node" src={node} alt={'Node.js logo'} text="nodeJs/" />
              </li>
              <li 
                onMouseEnter={() => setModel('imprint')} 
                onMouseLeave={() => setModel('microchip')}
                onTouchStart={() => setModel('imprint')} 
                onTouchEnd={() => setModel('microchip')}
              >
               <LinkElement name={playAnimation} delay={3.7} time={0.1} steps={1} to="/imprint" src={imprint} alt={'Imprint'} text="imprint/" />
              </li>
            </ul>
          </li>
          <li>
            <LinkElement name={playAnimation} blink={true} delay={3.9} time={0.1} steps={1} to="/" text="<Back>" />
          </li>
        </ul>
      </section>
      <ThreeJs model={model}/>
    </Fragment>
  );
};

export default Main;
