import { useEffect, useState, Fragment } from 'react';

import LinkElement from '../layout/LinkElement';
import TextElement from '../layout/TextElement';
import ThreeJs from '../threeJs/ThreeJs';

import about from '../../assets/img/about.svg';

const AboutMe = () => {
  const [playAnimation, setPlayAnimation] = useState(true);

  useEffect(() => {
    setPlayAnimation(JSON.parse(sessionStorage.getItem('playAnimation')));
  }, []);

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('playAnimation', 'false'));
  }, []);

  return (
    <Fragment>
      <section className={'containerLinks'}>
        <ul>
          <TextElement name={playAnimation} delay={0} time={0.1} steps={1} src={about} alt={'About me'} text="aboutMe/" />
          <TextElement name={playAnimation} delay={0.2} time={0.3} steps={8} text="cat aboutMe.txt" />
          <li>
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.8} time={0.1} steps={1} text="Hi There I'm Roman" />
                  <TextElement name={playAnimation} delay={1} time={0.1} steps={1} text="Welcome On My Webserver" />
                  <TextElement name={playAnimation} delay={1.2} time={0.1} steps={1} text="" />
                  <TextElement name={playAnimation} delay={1.4} time={0.1} steps={1} text="I Am A Java Developer By Profession" />
                  <TextElement name={playAnimation} delay={1.6} time={0.1} steps={1} text="Who Is Also Into Web Development" />
                  <TextElement name={playAnimation} delay={1.8} time={0.1} steps={1} text="My Tech Stack Is:" />
                  <ul className={'margin'}>
                    <TextElement name={playAnimation} delay={2} time={0.1} steps={1} text="Java" />
                    <TextElement name={playAnimation} delay={2.2} time={0.1} steps={1} text="JavaScript" />
                    <TextElement name={playAnimation} delay={2.4} time={0.1} steps={1} text="React.js" />
                    <TextElement name={playAnimation} delay={2.6} time={0.1} steps={1} text="Node.js" />
                  </ul>
                  <TextElement name={playAnimation} delay={2.8} time={0.1} steps={1} text="I Hope You Stay For A While!" />
                  <TextElement name={playAnimation} delay={3.0} time={0.1} steps={1} text="Enjoy My Projects =)" />
                </ul>
              </li>
          <li>
            <LinkElement name={playAnimation} blink={true} delay={3.2} time={0.1} steps={1} to="/main" text="<Back>" />
          </li>
        </ul>
      </section>
      <ThreeJs model={'r0m4n'}/>
    </Fragment>
  );
};

export default AboutMe;
