import { Link } from 'react-router-dom';

import css from './LinkElement.module.css';

const LinkElement = (props) => {
  return (
      <Link to={props.to}>
        <p className={`${props.name ? css.typer : ''} ${props.blink ? css.blink : ''}`}
            style={{  
              animationDelay: `${props.delay}s`,
              animationDuration: `${props.time}s`,
              animationTimingFunction: `steps(${props.steps} ,end)`,
              animationFillMode: 'forwards' 
            }}>
          {'>'} {props.src ? <img src={props.src} alt={props.alt}></img> : ''} {props.text}
        </p>
      </Link>
  );
};

export default LinkElement;
