import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';
import Window from './components/layout/Window'
import Welcome from './components/pages/Welcome';
import Main from './components/pages/Main';
import JavaScript from './components/pages/JavaScript';
import React from './components/pages/React';
import Nodejs from './components/pages/Nodejs';
import AboutMe from './components/pages/AboutMe';
import Imprint from './components/pages/Imprint';
import NotFound404 from './components/pages/NotFound404';

import './App.css';

function App() {
  
  localStorage.setItem('theme', 'dark');

  return (
    <Fragment>
      <Window> 
        <Routes>
          <Route path={'/'} element={<Welcome />} />
          <Route path={'main'} element={<Main />} />
          <Route path={'javascript'} element={<JavaScript />} />
            <Route
              path={'javascript/zeiterfassung'}
              element={() => {
                window.location.href = 'https://mr-r0m4n.de/javascript/zeiterfassung';
                return null;
              }}
            />
            <Route
              path={'javascript/kanban'}
              element={() => {
                window.location.href = 'https://mr-r0m4n.de/javascript/kanban';
                return null;
              }}
            />
            <Route
              path={'javascript/taschenrechner'}
              element={() => {
                window.location.href = 'https://mr-r0m4n.de/javascript/taschenrechner';
                return null;
              }}
            />
          <Route path={'react'} element={<React />} />
            <Route
              path={'react/pokedex'}
              element={() => {
                window.location.href = 'https://mr-r0m4n.de/react/pokedex';
                return null;
              }}
            />
            <Route
              path={'react/data0rama'}
              element={() => {
                window.location.href = 'https://mr-r0m4n.de/react/data0rama';
                return null;
              }}
            />
            <Route
              path={'react/shopyfly'}
              element={() => {
                window.location.href = 'https://mr-r0m4n.de/react/shopyfly';
                return null;
              }}
            />
          <Route path={'node'} element={<Nodejs />} />
            <Route
              path={'node/fakeshopdocs'}
              element={() => {
                window.location.href = 'https://mr-r0m4n.de/node/fakeshopapi';
                return null;
              }}
            />
          <Route path={'aboutme'} element={<AboutMe />} />
          <Route path={'imprint'} element={<Imprint />} />
          <Route path={'*'} element={<NotFound404 />} />
        </Routes>
      </Window>
    </Fragment>
  );
}

export default App;
