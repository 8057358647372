import { Canvas } from '@react-three/fiber'

import Scene from './Scene';
import AsciiRenderer from './AsciiRenderer';
import { useLayoutEffect, useRef, useState } from 'react';

const Test = (props) => {
  const ref = useRef();
  const [width, setWidth] = useState();

  useLayoutEffect(() => {
    function updateWidth() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', updateWidth);
    return () => window.addEventListener('resize', updateWidth);
  })

  useLayoutEffect(() => {
    setWidth(window.innerWidth)
  },[])

  return (
    <section ref={ref} className={'containerRechts'}>
        <Canvas gl={{alpha: true}}>
          <Scene model={props.model} width={width} />
          <AsciiRenderer />
        </Canvas>
    </section>
  )
}

export default Test;

