import css from './Footer.module.css';

import github from '../../assets/img/github.svg';
import linkedin from '../../assets/img/linkedin.svg';
import googleplay from '../../assets/img/googleplay.svg';
import appstore from '../../assets/img/appstore.svg';

const Footer = (props) => {
  
  const toggleTheme = () => {
    if (localStorage.getItem('theme') === null) {
      localStorage.setItem('theme', 'dark');
    } else {
      localStorage.removeItem('theme');
      sessionStorage.removeItem('matrix');
    }
    props.onClick(localStorage.getItem('theme'));
  };

  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <a href="https://github.com/Mr-R0m4n">
          <img src={github} alt="github"></img>
        </a>
        <a href="https://www.linkedin.com/in/roman-schumann-70bb3222a">
          <img src={linkedin} alt="linkedIn"></img>
        </a>
        <a href="https://play.google.com/store/apps/dev?id=6853764480907257913">
          <img src={googleplay} alt="google play store"></img>
        </a>
        <a href="https://apps.apple.com/us/app/beichtspiegel/id6468268376">
          <img src={appstore} alt="apple app store"></img>
        </a>
      </div>
      <label className={css.switch}>
        <input defaultChecked={localStorage.getItem('theme')} onClick={toggleTheme} type="checkbox" />
        <span className={`${css.slider} ${css.round}`}></span>
      </label>
    </footer>
  );
};

export default Footer;
