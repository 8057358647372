import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

import css from './Window.module.css';
import lightTheme from './light.module.css';
import darkTheme from './dark.module.css';
import matrixTheme from './matrix.module.css';
import Matrix from './Matrix';

const Window = (props) => {
  const [theme, setTheme] = useState(localStorage.getItem('theme'))
  const [matrix, setMatrix] = useState(sessionStorage.getItem('matrix'))

  const location = useLocation();
  useEffect(() => {
    const log = async () => {
      await fetch(`${location.pathname}`);
    };
    log();
  }, [location.pathname]);

  const toggleTheme = (theme) => {
    setTheme(theme)
    if(theme)
      setMatrix(null)
  }

  const toggleMatrix = (matrix) => {
    setMatrix(matrix)
  }

  return (
    <main className={`${theme ? darkTheme.background: lightTheme.background} ${css.background}`}>
      {theme && matrix ? <Matrix/> : ''}
      <article className={`${theme ? `${matrix ? matrixTheme.glas : darkTheme.glas}` : lightTheme.glas} ${css.glas}`}>
        <Header onClick={toggleMatrix}/>
          {props.children}
        <Footer onClick={toggleTheme}/>
        </article>
    </main>
  );
};

export default Window;
