import { useEffect, useRef, useState } from 'react';

function Matrix({ timeout = 50 }) {
    const [width, setWidth] = useState(document.body.offsetWidth);
    const [height, setHeight] = useState(document.body.offsetHeight);
    const canvas = useRef();

    useEffect(() => {
        const context = canvas.current.getContext('2d');

        context.fillStyle = '#000';
        context.fillRect(0, 0, width, height);

        const columns = Math.floor(width / 20) + 1;
        const yPositions = Array.from({ length: columns }).fill(0);

        context.fillStyle = '#000';
        context.fillRect(0, 0, width, height);

        const matrixEffect = () => {
            context.fillStyle = '#0001';
            context.fillRect(0, 0, width, height);

            context.fillStyle = '#0f0';
            context.font = '1rem monospace';

            yPositions.forEach((y, index) => {
                const text = String.fromCharCode(Math.random() * 128);
                const x = index * 20;
                context.fillText(text, x, y);

                if (y > 100 + Math.random() * 10000) {
                    yPositions[index] = 0;
                } else {
                    yPositions[index] = y + 20;
                }
            });
        };
        const interval = setInterval(matrixEffect, timeout);
        return () => {
            clearInterval(interval);
        };
    }, [timeout, width, height]);

    useEffect(()=> {
        function resize() {
            setHeight(document.body.offsetHeight);
            setWidth(document.body.offsetWidth);
        }
        window.addEventListener('resize', resize, false);
        
        return() => {
            window.removeEventListener('resize', resize);
        }
    })

    return (
        <div className={'matrix'}>
            <canvas
                ref={canvas}
                width={width}
                height={height}
            />
        </div>
    );
}

export default Matrix;