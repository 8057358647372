import { useEffect, useState, Fragment } from 'react';

import AElement from '../layout/AElement';
import LinkElement from '../layout/LinkElement';
import TextElement from '../layout/TextElement';
import ThreeJs from '../threeJs/ThreeJs';

import react from '../../assets/img/react.svg';

const React = () => {
  const [model, setModel] = useState('react');
  const [playAnimation, setPlayAnimation] = useState(true);

  useEffect(() => {
    setPlayAnimation(JSON.parse(sessionStorage.getItem('playAnimation')));
  }, []);

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('playAnimation', 'false'));
  }, []);

  return (
    <Fragment>
      <section className={'containerLinks'}>
        <ul>
          <TextElement name={playAnimation} delay={0} time={0.1} steps={1} src={react} alt={'React.js'} text="reactJs/" />
          <TextElement name={playAnimation} delay={0.2} time={0.1} steps={5} text="ls" />
          <li>
            <ul className={'margin'}>
              <li className='listElement' 
                  onMouseEnter={() => setModel('pokeball')} 
                  onMouseLeave={() => setModel('react')}
                  onTouchStart={() => setModel('pokeball')} 
                  onTouchEnd={() => setModel('react')}
              >
                <AElement name={playAnimation} delay={0.4} time={0.1} steps={1} to="https://mr-r0m4n.de/react/pokedex" text="pokedex/" />
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.4} time={0.1} steps={1} text="A Pokédex With The" />
                  <TextElement name={playAnimation} delay={0.4} time={0.1} steps={1} text="151 First Pokemon" />
                </ul>
              </li>
              <li className='listElement' 
                  onMouseEnter={() => setModel('chart')} 
                  onMouseLeave={() => setModel('react')}
                  onTouchStart={() => setModel('chart')} 
                  onTouchEnd={() => setModel('react')}
              >
                <AElement name={playAnimation} delay={0.6} time={0.1} steps={1} to="https://mr-r0m4n.de/react/data0rama" text="data0rama/" />
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.6} time={0.1} steps={1} text="A Chart Tool With Pie" />
                  <TextElement name={playAnimation} delay={0.6} time={0.1} steps={1} text="Line And Bar Chart View" />
                </ul>
              </li>
              <li className='listElement' 
                  onMouseEnter={() => setModel('shop')} 
                  onMouseLeave={() => setModel('react')}
                  onTouchStart={() => setModel('shop')} 
                  onTouchEnd={() => setModel('react')}
              >
                <AElement name={playAnimation} delay={0.8} time={0.1} steps={1} to="https://mr-r0m4n.de/react/shopyfly" text="shopyfly/" />
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.8} time={0.1} steps={1} text="A Fake Online Store" />
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <LinkElement name={playAnimation} blink={true} delay={1} time={0.1} steps={1} to="/main" text="<Back>" />
          </li>
        </ul>
      </section>
      <ThreeJs model={model} />
    </Fragment>
  );
};

export default React;
