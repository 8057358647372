import css from './AElement.module.css';

const AElement = (props) => {
  return (
    <a href={props.to}>
      <p className={ props.name ? css.typer : ''}
          style={{    
            animationDelay: `${props.delay}s`, 
            animationDuration: `${props.time}s`, 
            animationTimingFunction: `steps(${props.steps} ,end)`, 
            animationFillMode: 'forwards' 
          }}>
        {'>'} {props.src ? <img src={props.src} alt={props.alt}></img> : ''} {props.text}
      </p>
    </a>
  );
};

export default AElement;
