import { useEffect, useState, Fragment } from 'react';

import AElement from '../layout/AElement';
import LinkElement from '../layout/LinkElement';
import TextElement from '../layout/TextElement';
import ThreeJs from '../threeJs/ThreeJs';

import js from '../../assets/img/js.svg';

const JavaScript = () => {
  const [model, setModel] = useState('js');
  const [playAnimation, setPlayAnimation] = useState(true);

  useEffect(() => {
    setPlayAnimation(JSON.parse(sessionStorage.getItem('playAnimation')));
  }, []);

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('playAnimation', 'false'));
  }, []);

  return (
    <Fragment>
      <section className={'containerLinks'}>
        <ul>
          <TextElement name={playAnimation} delay={0} time={0.1} steps={1} src={js} alt={'Javascript'} text="javaScript/" />
          <TextElement name={playAnimation} delay={0.2} time={0.1} steps={5} text="ls" />
          <li>
            <ul className={'margin'}>
              <li className='listElement' 
                onMouseEnter={() => setModel('clock')} 
                onMouseLeave={() => setModel('js')}
                onTouchStart={() => setModel('clock')} 
                onTouchEnd={() => setModel('js')}
              >
                <AElement name={playAnimation} delay={0.4} time={0.1} steps={1} to="https://mr-r0m4n.de/javascript/zeiterfassung" text="punchClock/" />
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.4} time={0.1} steps={1} text="Working Time Tracking System" />
                </ul>
              </li>
              <li className='listElement' 
                onMouseEnter={() => setModel('kanban')} 
                onMouseLeave={() => setModel('js')}
                onTouchStart={() => setModel('kanban')} 
                onTouchEnd={() => setModel('js')}
              >
                <AElement name={playAnimation} delay={0.6} time={0.1} steps={1} to="https://mr-r0m4n.de/javascript/kanban" text="kanBamBoard/" />
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.6} time={0.1} steps={1} text="Fully Customizable Kanban Board" />
                </ul>
              </li>
              <li className='listElement' 
                onMouseEnter={() => setModel('calculator')} 
                onMouseLeave={() => setModel('js')}
                onTouchStart={() => setModel('calculator')} 
                onTouchEnd={() => setModel('js')}
              >
                <AElement name={playAnimation} delay={0.8} time={0.1} steps={1} to="https://mr-r0m4n.de/javascript/taschenrechner" text="calcotron/" />
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.8} time={0.1} steps={1} text="Calculator With Basic Functions" />
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <LinkElement name={playAnimation} blink={true} delay={1} time={0.1} steps={1} to="/main" text="<Back>" />
          </li>
        </ul>
      </section>
      <ThreeJs model={model} />
    </Fragment>
  );
};

export default JavaScript;
