import { useFrame, useThree } from '@react-three/fiber'
import { useEffect, useMemo } from 'react'
import { AsciiEffect } from 'three-stdlib'

const AsciiRenderer = ({ renderIndex = 1, characters = ' #', ...options }) => {
  const { size, gl, scene, camera } = useThree()

  const effect = useMemo(() => {
    const effect = new AsciiEffect(gl, characters, options)
    effect.domElement.style.position = 'absolute'
    effect.domElement.style.top = '0px'
    effect.domElement.style.left = '0px'
    effect.domElement.style.backgroundColor = 'transparent'
    effect.domElement.style.pointerEvents = 'none'
    return effect
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [characters, options.invert])

  useEffect(() => {
    gl.domElement.parentNode.appendChild(effect.domElement)
    return () => gl.domElement.parentNode.removeChild(effect.domElement)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [effect])

  useEffect(() => {
    effect.setSize(size.width, size.height)
  }, [effect, size])

  useFrame((state) => {
    effect.render(scene, camera)
  }, renderIndex)
}

export default AsciiRenderer;