import { useRef } from "react";
import { useLoader, useFrame } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import microchip3D from '../../assets/models/microchip.gltf';
import js3D from '../../assets/models/js.gltf'
import about3D from '../../assets/models/about.gltf'
import react3D from '../../assets/models/react.gltf';
import node3D from '../../assets/models/node.gltf';
import imprint3D from '../../assets/models/imprint.gltf';
import clock3D from '../../assets/models/clock.gltf';
import kanban3D from '../../assets/models/kanban.gltf';
import calculator3D from '../../assets/models/calculator.gltf';
import pokeball3D from '../../assets/models/pokeball.gltf';
import chart3D from '../../assets/models/chart.gltf';
import shop3D from '../../assets/models/shop.gltf';
import r0m4n3D from '../../assets/models/r0m4n.gltf';

const Scene = (props) => {
  const objRef = useRef();

  useFrame(() => {
    objRef.current.rotation.y += 0.01;
  });

  const microchip = useLoader(GLTFLoader, microchip3D);
  const about = useLoader(GLTFLoader, about3D);
  const js = useLoader(GLTFLoader, js3D);
  const react = useLoader(GLTFLoader, react3D);
  const node = useLoader(GLTFLoader, node3D);
  const imprint = useLoader(GLTFLoader, imprint3D);
  const clock = useLoader(GLTFLoader, clock3D);
  const kanban = useLoader(GLTFLoader, kanban3D);
  const calculator = useLoader(GLTFLoader, calculator3D);
  const pokeball = useLoader(GLTFLoader, pokeball3D);
  const chart = useLoader(GLTFLoader, chart3D);
  const shop = useLoader(GLTFLoader, shop3D);
  const r0m4n = useLoader(GLTFLoader, r0m4n3D);

  let scale;
  if (props.width < 1401) {
    scale = 20;
  }
  else if (props.width < 1700) {
    scale = 35;
  }
  else {
    scale = 40;
  }
  
  return (
    <mesh ref={objRef} scale={[scale, scale, scale]} rotation-y={Math.PI * 0.125}>
      {props.model === 'microchip' ? <primitive object={microchip.scene}/> : ''}
      {props.model === 'about' ? <primitive object={about.scene}/> : ''}
      {props.model === 'js' ? <primitive object={js.scene}/> : ''}
      {props.model === 'react' ? <primitive object={react.scene}/> : ''}
      {props.model === 'node' ? <primitive object={node.scene}/> : ''}
      {props.model === 'imprint' ? <primitive object={imprint.scene}/> : ''}
      
      {props.model === 'clock' ? <primitive object={clock.scene}/> : ''}
      {props.model === 'kanban' ? <primitive object={kanban.scene}/> : ''}
      {props.model === 'calculator' ? <primitive object={calculator.scene}/> : ''}
      {props.model === 'pokeball' ? <primitive object={pokeball.scene}/> : ''}
      {props.model === 'chart' ? <primitive object={chart.scene}/> : ''}
      {props.model === 'shop' ? <primitive object={shop.scene}/> : ''}
      {props.model === 'r0m4n' ? <primitive object={r0m4n.scene}/> : ''}
    </mesh>
  );
};

export default Scene;
