import { useEffect, useState, Fragment } from 'react';

import AElement from '../layout/AElement';
import LinkElement from '../layout/LinkElement';
import TextElement from '../layout/TextElement';
import ThreeJs from '../threeJs/ThreeJs';

import node from '../../assets/img/node.svg';

const Nodejs = () => {
  const [model, setModel] = useState('node');
  const [playAnimation, setPlayAnimation] = useState(true);

  useEffect(() => {
    setPlayAnimation(JSON.parse(sessionStorage.getItem('playAnimation')));
  }, []);

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('playAnimation', 'false'));
  }, []);

  return (
    <Fragment>
      <section className={'containerLinks'}>
        <ul>
          <TextElement name={playAnimation} delay={0} time={0.1} steps={1} src={node} alt={'Node.js'} text="nodeJs/" />
          <TextElement name={playAnimation} delay={0.2} time={0.1} steps={5} text="ls" />
          <li>
            <ul className={'margin'}>
              <li className='listElement' 
                onMouseEnter={() => setModel('shop')} 
                onMouseLeave={() => setModel('node')}
                onTouchStart={() => setModel('shop')} 
                onTouchEnd={() => setModel('node')}
              >
                <AElement name={playAnimation} delay={0.4} time={0.1} steps={1} to="https://mr-r0m4n.de/node/fakeshopapi" text="fakeShopApi/" />
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.4} time={0.1} steps={1} text="A Fake Shop API With" />
                  <TextElement name={playAnimation} delay={0.4} time={0.1} steps={1} text="Products, Users and Carts" />
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <LinkElement name={playAnimation} blink={true} delay={0.6} time={0.1} steps={1} to="/main" text="<Back>" />
          </li>
        </ul>
      </section>
      <ThreeJs model={model} />
    </Fragment>
  );
};

export default Nodejs;
