import { useEffect, useState, Fragment } from 'react';

import AElement from '../layout/AElement';
import LinkElement from '../layout/LinkElement';
import TextElement from '../layout/TextElement';
import ThreeJs from '../threeJs/ThreeJs';

import imprint from '../../assets/img/imprint.svg';

const Impressum = () => {
  const [playAnimation, setPlayAnimation] = useState(true);

  useEffect(() => {
    setPlayAnimation(JSON.parse(sessionStorage.getItem('playAnimation')));
  }, []);

  useEffect(() => {
    setTimeout(() => sessionStorage.setItem('playAnimation', 'false'));
  }, []);

  return (
    <Fragment>
      <section className={'containerLinks'}>
        <ul>
          <TextElement name={playAnimation} delay={0} time={0.1} steps={1} src={imprint} alt={'Imprint'} text="imprint/" />
          <TextElement name={playAnimation} delay={0.2} time={0.3} steps={8} text="cat imprint.txt" />
          <li>
            <ul className={'margin'}>
              <TextElement name={playAnimation} delay={0.6} time={0.1} steps={1} text="Angaben gemäß $ 5 TMG" />
              <li>
                <ul className={'margin'}>
                  <TextElement name={playAnimation} delay={0.8} time={0.1} steps={1} text="Roman Schumann" />
                  <TextElement name={playAnimation} delay={1} time={0.1} steps={1} text="Lindenstraße 54" />
                  <TextElement name={playAnimation} delay={1.2} time={0.1} steps={1} text="56575 Weißenthurm" />
                  <TextElement name={playAnimation} delay={1.4} time={0.1} steps={1} text="Telefon: +49(0)1711135010" />
                  <TextElement name={playAnimation} delay={1.6} time={0.1} steps={1} text="Mail: webmaster@mr-r0m4n.de" />
                </ul>
              </li>
              <AElement name={playAnimation} blink={false} delay={1.8} time={0.1} steps={1} to="https://www.e-recht24.de" text="eRecht24" />
            </ul>
          </li>
          <li>
            <LinkElement name={playAnimation} blink={true} delay={2} time={0.1} steps={1} to="/main" text="<Back>" />
          </li>
        </ul>
      </section>
      <ThreeJs model={'imprint'}/>
    </Fragment>
  );
};

export default Impressum;
